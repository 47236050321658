import React from "react"

import { PageLayout } from "@components/layouts"
import SEO from "@components/seo"

import SingleColumnBlock from "@components/singleColumnBlock"

import {
  RequestMoreInfo,
} from "@components/Forms"

const NotFoundPage = () => (
  <PageLayout>
    <SEO title="404 | Page Not Found | Treats' Beats" />
    <SingleColumnBlock
      textPadded
      text={
        <div>
          <h1>Page not found</h1>
          <p>Go to the <a href="/">homepage</a> where you can learn more about Treats' Beats and request invite to the platform.</p>
          <h4>Or tell us where you are and what you want. We will try to help.</h4>
          <RequestMoreInfo />
        </div>
      }
    />
  </PageLayout>
)

export default NotFoundPage
